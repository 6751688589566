// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61700: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { text5, neutral1, background6, background1, inform },
    },
  } = themeVars;

  return {
    LoginFormDesktop: {
      popUpWrapper: {
        color: text5,
        background: '#f6f6f6',
        boxShadow: 'none',
        '&:before': {
          background: '#f6f6f6',
        },
      },
      inputWrapper: {
        borderRadius: '2px',
        InputField: {
          border: '1px solid #9c9c9c',
        },
      },
    },
    PasswordResetWithEmail: {
      resetPassword: {
        color: inform,
      },
      resetPasswordDescription: {
        color: inform,
      },
      contactUsWrap: {
        '&:hover': {
          opacity: 1,
        },
      },
      contactUsLink: {
        color: '#8B8C97',
        fontWeight: 'normal',
        '&:hover': {
          color: neutral1,
        },
      },
      passwordResetDebounce: {
        color: '#8B8C97',
        '@media (pointer: coarse)': {
          color: '#8B8C97',
        },
      },
    },
    LoginPage: {
      heading: {
        color: background6,
      },
      wrapperMobile: {
        color: background1,
      },
    },
  };
};
