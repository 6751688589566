// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu61700: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, accent },
    },
  } = themeVars;
  const menuItemAfterElement = {
    content: '""',
    position: 'absolute',
    textAlign: 'center',
    bottom: '-27px',
    left: '6%',
    width: '90%',
    borderBottom: `2px solid #fbb374`,
  };
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: white,
        '&:hover, &:focus': {
          color: '#FF9933',
          '&:after': menuItemAfterElement,
        },
        '@media screen and (max-width: 1220px)': {
          fontSize: 12,
          padding: '0 7px',
        },
      },
      menuItemActive: {
        color: '#fbb374',
        '&:after': menuItemAfterElement,
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        '&:hover, &:focus, &.active': {
          color: accent,
        },
      },
    },
  };
};
