// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBonuses61700 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: { white },
      colorCombinations: { whiteAccent },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        background: '#FF9933',
        color: white,
        '&:hover': {
          background: '#FFB951',
          color: white,
        },
        '&.active': {
          background: '#FFB951',
          color: white,
        },
      },
      tabsItemMobile: {
        ...whiteAccent,
        borderRight: 'none',
        '&.active': {
          background: '#FFB951',
          color: white,
        },
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
      bonusContainer: {
        border: 'none',
        background: 'transparent',
        padding: 0,
      },
    },
    Bonuses: {
      root: {
        border: 'none',
        background: white,
      },
    },
  };
};
