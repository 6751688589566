import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu61700: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whiteNeutral3 },
      colorTypes: { text2, white, primary, background6, accent },
    },
    margins,
  } = themeVars;

  return {
    SubMenuListUIMobile: {
      link: {
        '&.active': {
          background: '#2255A4',
        },
      },
    },
    SportsMenuDesktop: {
      sport: {
        color: white,
        background: background6,
      },
      region: {
        color: white,
        background: background6,
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        background: white,
      },
      title: {
        color: white,
      },
    },
    HorizontalSportsListUISmallIcons: {
      item: {
        color: text2,
        borderRight: 'none',
        padding: [0, margins.md],
      },
      sportLink: {
        padding: 0,
        transition: '0.2s ease-in',
        color: '#99ADCD',
        '&.active, &:hover': {
          color: primary,
        },
      },
    },
    SportsMenuMobile: {
      region: {
        '&.active': {
          ...whiteNeutral3,
          '&:hover': whiteNeutral3,
        },
        '&:hover': whiteNeutral3,
      },
    },
    SportsMenuMobileRegions: {
      region: {
        background: '#2255A4',
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: accent,
        },
      },
      sportName: {
        color: white,
        '&.active': {
          color: accent,
        },
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: background6,
      },
    },
  };
};
