// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu61700: JSSThemeDefaultDropdownMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, text5 },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      selectorWrap: {
        color: white,
      },
      item: {
        background: white,
        color: text5,
        '&:hover,&.active': { color: white },
      },
      list: {
        '&:before': {
          background: white,
        },
      },
    },

    dropdownMobile: {
      select: {
        color: white,
      },
      description: {
        color: white,
      },
      chevron: {
        color: white,
      },
    },
  };
};
