// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61700: JSSThemeDefaultHeaderMobileType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background6, white },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      menuRight: {
        color: white,
        background: background6,
      },
      iconContainer: {
        color: white,
      },
      menuContainer: {
        background: background6,
      },
    },
  };
};
