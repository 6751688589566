// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeCookies61700 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { background6 },
    },
  } = themeVars;
  return {
    cookiesPolicy: {
      CookiesPolicyRoot: {
        color: background6,
        '@media (pointer: coarse)': {
          color: background6,
        },
      },
      CookiesPolicyTableHeading: {
        color: background6,
        '@media (pointer: coarse)': {
          color: background6,
        },
      },
      CookiesPolicyTableTh: {
        backgroundColor: background6,
      },
      CookiesPolicyCell: {
        color: background6,
      },
    },
  };
};
