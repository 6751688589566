// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBalance61700 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorCombinations: { whitePrimary },
      colorTypes: {
        white,
        background2,
        text5,
        shadow,
        primary,
        text4,
        background5,
      },
    },
  } = themeVars;

  return {
    BalanceComponent: {
      titleContent: {
        color: white,
      },
      userMenuWrap: {
        background: white,
        color: text5,
        borderRadius: '5px',
        '&:before': {
          background: white,
        },
      },
      accountMenuWrap: {
        borderBottom: `1px solid ${shadow}`,
        borderTop: `1px solid ${shadow}`,
      },
      balanceWrap: {
        padding: [margins.md, 0],
      },
      mobileMoneyWrap: {
        margin: [margins.md, 0],
      },
      logoutWrap: {
        color: text5,
        fontWeight: 'bold',
        '&:hover': {
          opacity: 0.7,
        },
      },
      mobileLogoutWrap: {
        opacity: 0.6,
      },
    },
    UsernameCompanent: {
      userName: {
        color: text5,
        opacity: 0.5,
      },
      accountNumber: {
        padding: [margins.xs, 0],
        color: text5,
        opacity: 0.5,
      },
    },
    UsernameCompanentMobile: {
      userNameMobile: {
        color: text5,
        fontWeight: 'bold',
      },
      accountNumberMobile: {
        color: text5,
        opacity: 1,
      },
    },
    Bonus: {
      bonusTitle: {
        color: text5,
      },
      bonusValue: {
        color: primary,
      },
      bonusValueMobile: {
        color: background5,
      },
      bonusTitleMobile: {
        color: white,
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        padding: [margins.md, 0],
        color: text5,
        '&:hover': {
          color: text4,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        height: '100%',
        background: background2,
      },
      userMenuItem: {
        color: white,
        padding: [margins.xm, margins.lg],
        '&.active, &:hover': {
          ...whitePrimary,
        },
      },
    },
  };
};
