// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeSearch61700 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { neutral1 },
    },
  } = themeVars;

  return {
    SearchBar: {
      inputContainer: {
        color: neutral1,
      },
      resultContainer: {
        maxHeight: 'calc(100vh - 93px - 37px)',
        '@media (pointer: coarse)': {
          background: ' #f1f1f1',
        },
      },
    },
    SportsbookSearchResult: {
      title: {
        color: '#2255a4',
      },
      event: {
        background: '#fff',
      },
      description: {
        color: '#8B8C97',
      },
      marketCount: {
        background: '#FF9933',
        fontWeight: 'bold',
        color: '#fff',
        '&:hover': {
          color: '#fff',
          background: '#FFB951',
        },
      },
    },
  };
};
