// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, applyOnlyMobile } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip61700';
import { JSSThemeMyBets61700 } from './JSSThemeMyBets61700';
import { JSSThemeHeaderMobile61700 } from './JSSThemeHeaderMobile61700';
import { JSSThemeSportsMenu61700 } from './JSSThemeSportsMenu61700';
import { JSSThemeBannerItems61700 } from './JSSThemeBannerItems61700';
import { JSSThemeForm61700 } from './JSSThemeForm61700';
import { JSSThemeBonuses61700 } from './JSSThemeBonuses61700';
import { JSSThemePromotions61700 } from './JSSThemePromotions61700';
import { JSSThemeSitemap61700 } from './JSSThemeSitemap61700';
import { JSSThemeCoupons61700 } from './JSSThemeCoupons61700';
import { JSSThemeSearch61700 } from './JSSThemeSearch61700';
import { JSSThemeHeaderMenu61700 } from './JSSThemeHeaderMenu61700';
import { JSSThemeDropdownMenu61700 } from './JSSThemeDropdownMenu61700';
import { JSSThemeCookies61700 } from './JSSThemeCookies61700';

import { JSSThemeOutcomes61700 } from './JSSThemeOutcomes61700';
import { JSSThemeBalance61700 } from './JSSThemeBalance61700';
import { JSSThemeHeader61700 } from './JSSThemeHeader61700';
import { JSSThemeAuthForm61700 } from './JSSThemeAuthForm61700';
import { JSSThemeAccountSection61700 } from './JSSThemeAccountSection61700';
import { JSSThemeLanguageSwitcher61700 } from './JSSThemeLanguageSwitcher61700';
import { JSSTheme61700Resets } from './JSSTheme61700Resets';
import { JSSThemeCasino61700 } from './JSSThemeCasino61700';
import { JSSThemePopularLeagues61700 } from './JSSThemePopularLeagues61700';
import { JSSThemeLive61700 } from './JSSThemeLive61700';
import { JSSThemeRegistration61700 } from './JSSThemeRegistration61700';
import { JSSThemeFooterAll61700 } from './JSSThemeFooterAll61700';
import { JSSThemeOryx61700 } from './JSSThemeOryx61700';

export const colorTypes61700: ColorTypes = {
  ...colorTypes,
  primary: '#2255A4',
  text1: 'rgba(245, 245, 245, 0.6)',
  text2: '#000',
  text3: '#a94442',
  text4: '#9c9c9c',
  text5: '#8B8C97',
  background1: '#8B8C97',
  background2: '#8B8C97',
  background3: '#40701E',
  background4: '#e7e7e7',
  background5: '#FF9933',
  background6: '#4e5a6c', // new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#FF9933',
  success: '#21a22d',
  error: '#f14d4d',
  error2: '#d82e2e',
  error3: '#d82e2e',
  warning: '#ffa500',
  inform: '#2255A4',
  neutral1: '#ccc',
  neutral2: '#eabd11',
  neutral3: '#FF9933',
  neutral4: '#F1F1F1',
  border1: '#eabb5c',
  border2: '#404040',
};

const pick = ({
  text2Bg2,
  Bg2text2,
  text2Bg1,
  whiteWarning,
  whiteAccent,
  whiteBorder2,
  text1Bg2,
  text1Bg1,
  bg2Neutral1,
  whiteInform,
  whiteNeutral3,
  bg1White,
  bg1Bg4,
  bg4Bg1,
  whitePrimary,
  whiteBg2,
  text1Border2,
  errorWhite,
  text2Accent,
  bg1Neutral1,
  neutral3Text2,
  neutral1Bg1,
  text2Primary,
  text5White,
  text2Error,
  text2Error2,
  whiteText4,
}) => ({
  text2Bg2,
  Bg2text2,
  whiteWarning,
  whiteAccent,
  whiteBorder2,
  text2Bg1,
  text1Bg2,
  text1Bg1,
  bg2Neutral1,
  whiteInform,
  whiteNeutral3,
  bg1White,
  bg1Bg4,
  bg4Bg1,
  whitePrimary,
  whiteBg2,
  text1Border2,
  errorWhite,
  text2Accent,
  bg1Neutral1,
  neutral3Text2,
  neutral1Bg1,
  text2Primary,
  text5White,
  text2Error,
  text2Error2,
  whiteText4,
});

export const colorCombinations61700 = getColorCombinations(
  colorTypes61700,
  pick
);

export const JSSThemeVariables61700: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorCombinations: colorCombinations61700,
    colorTypes: colorTypes61700,
  },
};

export const JSSTheme61700: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { neutral3Text2, text2Accent },
      colorTypes: {
        background1,
        neutral4,
        text5,
        white,
        primary,
        accent,
        background6,
        text1,
      },
    },
    fontSizes,
    margins,
    fonts,
  } = themeVars;
  return {
    mybets: JSSThemeMyBets61700(themeVars),
    outcomes: JSSThemeOutcomes61700(themeVars),
    betslip: betslip(themeVars),
    headerMobile: JSSThemeHeaderMobile61700(themeVars),
    promotions: JSSThemePromotions61700(themeVars),
    sitemap: JSSThemeSitemap61700(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher61700(themeVars),
    registration: JSSThemeRegistration61700(themeVars),
    footerAll: JSSThemeFooterAll61700(themeVars),
    oryx: JSSThemeOryx61700(themeVars),
    NoBetsMessage: {
      root: {
        background: white,
        color: background6,
        fontWeight: 'normal',
      },
    },
    Heading: {
      common: {
        fontSize: '18px',
        color: primary,
        fontFamily: fonts.agora,
        padding: '5px 15px 5px',
        '&.headingMobile': {
          color: primary,
        },
      },
    },
    Button: {
      linkDefault: {
        color: text5,
        '&:hover, &:active, &:focus': {
          color: text5,
        },
      },
      linkSecondary: {
        color: white,
        '&:hover': {
          color: white,
          opacity: 0.7,
        },
      },
      root: {
        padding: ['1px', margins.xm, '0'],
        lineHeight: '31px',
      },
      linkAccent: {
        color: background6,
        '&:hover': {
          color: background6,
          textDecoration: 'none',
        },
      },
      primary: {
        background: accent,
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            background: '#FFB951',
            color: white,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...text2Accent,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: '#FFB951',
            color: white,
          },
        },
      },
      default: {
        background: '#2255A4',
        color: white,
        '&:hover, &:active': {
          background: '#4283E7',
          color: white,
          opacity: 0.9,
        },
      },
      secondary: {
        background: '#2255A4',
        color: white,
        ...applyOnlyDesktop({
          '&:hover, &:focus': {
            background: '#4283E7',
            color: white,
            opacity: 0.9,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: background6,
            color: text1,
          },
        },
      },
      accent: {
        background: primary,
        color: white,
        '&:hover': {
          background: '#4283E7',
          color: white,
        },
        '&:disabled': {
          background: primary,
          color: white,
          opacity: '.65',
          '&:hover, &:active': {
            background: '#4283E7',
            color: white,
            opacity: '.65',
          },
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        fontWeight: 'normal',
        boxShadow: 'none',
        background: '#F1F1F1',
        color: background6,
        padding: '1px 10px 0',
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: primary,
      },
    },
    OfflineMessage: {
      popup: {
        color: 'black',
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        color: '#8B8C97',
      },
    },
    banners: JSSThemeBannerItems61700(themeVars),
    sportsMenu: JSSThemeSportsMenu61700(themeVars),
    form: JSSThemeForm61700(themeVars),
    bonuses: JSSThemeBonuses61700(themeVars),
    coupons: JSSThemeCoupons61700(themeVars),
    header: JSSThemeHeader61700(themeVars),
    headerMenu: JSSThemeHeaderMenu61700(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61700(themeVars),
    accountSection: JSSThemeAccountSection61700(themeVars),
    popularLeagues: JSSThemePopularLeagues61700(themeVars),
    search: JSSThemeSearch61700(themeVars),
    cookies: JSSThemeCookies61700(themeVars),
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: text5,
        borderTop: `1px solid ${background1}`,
      },
      stakePotential: {
        color: text5,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        borderTop: `1px solid ${background1}`,
      },
      panel: {
        background: white,
        padding: 0,
      },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      link: {
        color: primary,
        '&:hover': {
          color: white,
        },
      },
      h2: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        background: neutral4,
        color: primary,
      },
      dateTitle: {
        background: neutral4,
        color: background6,
      },
      dateEvent: {
        borderBottom: `1px solid ${text5}`,
        background: neutral4,
        color: primary,
        '&:hover': {
          cursor: 'pointer',
          color: white, // $live-menu-event-color-hover;
          background: primary, // $live-menu-event-background-color-hover
        },
      },
    },
    BetslipWithBonusMyBetsStakePotentialTotalUI: {
      info: {
        color: text5,
      },
      summaryTitle: {
        maxWidth: '40%',
      },
      potentialReturnValue: {
        maxWidth: '60%',
        fontSize: 17,
      },
    },
    balance: JSSThemeBalance61700(themeVars),
    authForm: JSSThemeAuthForm61700(themeVars),
    casino: JSSThemeCasino61700(themeVars),
    live: JSSThemeLive61700(themeVars),
    resets: JSSTheme61700Resets(themeVars),
  };
};

export const getJSSThemePatterns61700: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: {
        text2Accent,
        text2Primary,
        neutral3Text2,
        text2Error2,
      },
      colorTypes: { white, neutral3, error },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        ...text2Primary,
        ...applyOnlyDesktop({
          '&:hover': {
            background: '#FFB951',
            color: white,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...text2Accent,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: '#FFB951',
            color: white,
          },
        },
      },
      secondary: {
        ...neutral3Text2,
        ...applyOnlyDesktop({
          '&:hover, &:focus': {
            background: white,
            color: neutral3,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        },
      },
      accent: {
        ...text2Error2,
        '&:hover': {
          ...text2Error2,
        },
        '&:disabled': {
          background: error,
          color: white,
          opacity: '.65',
          '&:hover, &:active': {
            background: error,
            color: white,
            opacity: '.65',
          },
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
