// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader61700: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    colors: {
      colorTypes: { primary },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: primary,
      },
      authContentWrap: {
        justifyContent: 'flex-start',
        '@media screen and (max-width: 1500px)': {
          marginRight: 10,
        },
      },
      authWrapper: {
        padding: 0,
      },
      extraUserElementsWrapper: {
        lineHeight: '15px',
      },
    },
    LogoBrand: {
      logoLink: {
        height: 37,
      },
      logoWrapper: {
        '@media screen and (max-width: 1500px)': {
          width: 230,
        },
      }
    },
  };
};
