// @flow
import type { JSSThemeDefaultResetsType } from '../JSSThemeDefaultResets';

export const JSSTheme61700Resets: JSSThemeDefaultResetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteNeutral3 },
      colorTypes: { white },
    },
    margins,
  } = themeVars;
  return {
    button: {
      padding: ['1px', margins.xm, '0'],
      lineHeight: '31px',
      ...whiteNeutral3,
      '&:hover, &:active': {
        color: white,
        background: '#338005',
      },
    },
  };
};
