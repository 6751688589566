// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets61700: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { bg2Neutral1 },
      colorTypes: { background2, white },
    },
  } = themeVars;
  return {
    ConfirmationBox: {
      confirmation: {
        background: '#f1f1f1',
      },
      text: {
        color: background2,
      },
      buttonsContainer: {
        background: '#f1f1f1',
      },
    },
    CashOut: {
      success: bg2Neutral1,
    },
    MyBets: {
      content: {
        background: white,
      },
    },
  };
};
